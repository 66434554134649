* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wheel-of-fortune-app {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

#wheelOfFortune {
    display: inline-block;
    position: relative;
    overflow: hidden;
}

#wheel {
    display: block;
    width: 100%;
    aspect-ratio: 1/1;
    max-width: 600px;
    background: red;
    background: linear-gradient(90deg, rgba(237,232,220,1) 0%, rgba(0,185,151,1) 25%, rgba(1,255,0,1) 41%, rgba(56,0,255,1) 54%, rgba(0,255,252,1) 74%, rgba(255,0,0,1) 100%);
}

hr.divider {
    border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
    margin: 10px auto;
}

.powered-by {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin: 10px 0 0;
    font-size: 12px;
    /* // italic */
    font-style: italic;
}

.relative {}
.over-wheel {

    border: 1px solid aliceblue;
    background: #f8b771;
    padding: 15px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px #ccc;
    width: 100%;
}

.win-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-law {
    font-size: 11px;
    text-align: center;
    margin-top: 10px;
}

.win-product {
    background-color: aliceblue;
    padding: 15px 20px;
    border-radius: 10px;
    font-size: 25px;
    margin: 10px 0 15px;
    display: inline-block;
}

.rotate {
    animation-play-state: running !important;
    animation: rotation 1s linear 0s infinite;
}
@keyframes rotation {
    to {
        transform: rotate(360deg);
    }
}

.spin {
    font: 1.5em/0 sans-serif;
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    margin: -15%;
    background: #fff;
    color: #fff;
    box-shadow: 0 0 0 8px currentColor, 0 0 15px 5px rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    transition: 0.8s;
}

.spin::after {
    content: '';
    position: absolute;
    top: -17px;
    border: 10px solid transparent;
    border-bottom-color: currentColor;
    border-top: none;
}

.button-play {
    position: relative;
    display: inline-block;
    margin: 20px;
    background: none;
    border: none;
}

.button-play.disabled a {
    pointer-events: none;
    cursor: auto;
}

.button-play a {
    cursor: pointer;
    color: white;
    font-family: Helvetica, sans-serif;
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    text-decoration: none;
    background-color: #FFA12B;
    background-image: linear-gradient(6deg, rgba(195,65,68,1) 0%, rgba(213,34,37,1) 55%, rgba(218,25,28,1) 100%);
    display: block;
    position: relative;
    padding: 20px 40px;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-shadow: 0 1px 0 #000;
    filter: dropshadow(color=#000, offx=0px, offy=1px);

    -webkit-box-shadow: inset 0 1px 0 #d99a9c, 0 10px 0 #790002;
    -moz-box-shadow: inset 0 1px 0 #d99a9c, 0 10px 0 #790002;
    box-shadow: inset 0 1px 0 #d99a9c, 0 10px 0 #790002;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.button-play a:active {
    top: 10px;
    background-color: #F78900;

    -webkit-box-shadow: inset 0 1px 0 #d99a9c, inset 0 -3px 0 #790002;
    -moz-box-shadow: inset 0 1px 0 #d99a9c, inset 0 -3pxpx 0 #790002;
    box-shadow: inset 0 1px 0 #d99a9c, inset 0 -3px 0 #790002;
}
.button-play:after {
    content: "";
    height: 100%;
    width: 100%;
    padding: 4px;
    position: absolute;
    bottom: -15px;
    left: -4px;
    z-index: -1;
    background-color: #2B1800;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
